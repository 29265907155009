import { PhotoCamera, Edit } from '@material-ui/icons';
import { Validation } from './CommonData';

export const FormInputsData = [
  {
    groupLabel: 'Personal Detail',
    groupLabelClass: 'fieldset-label',
    key: 'personal_detail',
    groupClass: 'form-fieldset',
    inputs: [
      {
        label: 'First Name',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'first_name',
        name: 'first_name',
        placeholder: 'First Name',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        minLength: 3,
        maxLength: 100,
        errors: [
          { type: 'required', message: 'Please enter the first name' },
          { type: 'minLength', message: 'First name must be atleast three characters' },
        ],
      },
      {
        label: 'Last Name',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'last_name',
        name: 'last_name',
        placeholder: 'Last Name',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        minLength: 1,
        maxLength: 100,
        errors: [
          { type: 'required', message: 'Please enter the first name' },
          { type: 'minLength', message: 'Last name must be atleast one characters' },
        ],
      },
      {
        label: 'Date of Birth',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'dob',
        name: 'dob',
        placeholder: 'Date of Birth',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'date',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Personal Email',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'personal_email',
        name: 'personal_email',
        placeholder: 'Personal Email',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Phone Number',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'phone',
        name: 'phone',
        placeholder: 'Phone Number',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        pattern: Validation.numberAndPlus,
        maxLength: 20,
        minLength: 5,
        errors: [
          { type: 'pattern', message: 'Please enter a valid phone number' },
          { type: 'minLength', message: 'Phone number must be greater than 5 digits' },
        ],
      },
      {
        label: 'Company',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'client_id',
        name: 'client_id',
        groupLabelPlace: 'company_hr_detail',
        placeholder: 'Select Company',
        hide: false,
        optionsKey: 'clients',
        valueId: 'client_id',
        displayKey: 'client_name',
        hiddenKey: 'client_id',
        formControlClass: 'input-form-control',
        type: 'select',
        fieldClass: 'multiselect textBoxStyle',
        options: [],
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
        isRequired: true,
        errors: [{ type: 'required', message: 'Please choose company' }],
      },
      {
        label: 'Flipkoins Email',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'flipkoins_email',
        name: 'flipkoins_email',
        placeholder: 'Flipkoins Email',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        pattern: Validation.email,
        maxLength: 100,
        errors: [
          { type: 'required', message: 'Please enter the flipkoins email' },
          { type: 'pattern', message: 'Please enter a valid email' },
        ],
      },
      {
        label: 'Company Email',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'company_email',
        name: 'company_email',
        placeholder: 'Company Email',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'LinkedIn',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'linkedin',
        name: 'linkedin',
        placeholder: 'LinkedIn',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Country',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'country_id',
        name: 'country_id',
        placeholder: 'Select or Create Country',
        hide: false,
        optionsKey: 'country',
        valueId: 'country_id',
        displayKey: 'country',
        formControlClass: 'input-form-control',
        type: 'singleSelectCreate',
        fieldClass: 'textBoxStyle multiSelectStyle', // textBoxStyle multiSelectStyle z-index-4
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
      },
      {
        label: 'State',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'state_id',
        name: 'state_id',
        placeholder: 'Select or Create State',
        hide: false,
        optionsKey: 'state',
        valueId: 'state_id',
        displayKey: 'state',
        formControlClass: 'input-form-control',
        type: 'singleSelectCreate',
        fieldClass: 'textBoxStyle multiSelectStyle', // textBoxStyle multiSelectStyle z-index-4
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
      },
      {
        label: 'City',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'city_id',
        name: 'city_id',
        placeholder: 'Select or Create City',
        hide: false,
        optionsKey: 'city',
        valueId: 'city_id',
        displayKey: 'city',
        formControlClass: 'input-form-control',
        type: 'singleSelectCreate',
        fieldClass: 'textBoxStyle multiSelectStyle', // textBoxStyle multiSelectStyle z-index-4
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
      },
      {
        type: 'renderer',
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
        key: 'UploadResume',
      },
    ],
  },
  {
    groupLabel: 'Professional Detail',
    groupLabelClass: 'fieldset-label',
    key: 'professional_detail',
    name: 'professional_detail',
    groupClass: 'form-fieldset',
    inputs: [
      {
        label: 'Employee ID',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'employee_id',
        name: 'employee_id',
        placeholder: 'Employee ID',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Skills',
        xs: 12,
        sm: 12,
        md: 12,
        lg: 8,
        xl: 8,
        key: 'skill_id',
        name: 'skill_id',
        placeholder: 'Select Skills',
        hide: false,
        formControlClass: 'input-form-control',
        optionsKey: 'skills',
        valueId: 'skill_id',
        displayKey: 'skill_name',
        type: 'multiSelectCreate',
        fieldClass: 'multiSelectStyle z-index-3',
        variant: 'outlined',
        margin: 'dense',
        size: 'small',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Contract Type',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'contract_type_id',
        name: 'contract_type_id',
        placeholder: 'Select Contract Type',
        hide: false,
        optionsKey: 'contractType',
        valueId: 'contract_type_id',
        displayKey: 'contract_type_name',
        formControlClass: 'input-form-control',
        type: 'select',
        fieldClass: 'multiselect textBoxStyle',
        options: [],
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
      },
      {
        label: 'Joining Date',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'joining_date',
        name: 'joining_date',
        placeholder: 'Joining Date',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'date',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'End Date',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'end_date',
        name: 'end_date',
        placeholder: 'End Date',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'date',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Designation',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'designation',
        name: 'designation',
        placeholder: 'Designation',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Current Project',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'current_project',
        name: 'current_project',
        placeholder: 'Current Project',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Reporting Manager',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'reporting_manager',
        name: 'reporting_manager',
        placeholder: 'Reporting Manager',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
    ],
  },
  {
    groupLabel: 'HR Detail',
    groupLabelClass: 'fieldset-label',
    key: 'hr_detail',
    hiddenKey: 'hr_detail',
    groupClass: 'form-fieldset',
    inputs: [
      {
        label: 'CTC',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'ctc',
        name: 'ctc',
        placeholder: 'CTC',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Laptop Detail',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'laptop_detail',
        name: 'laptop_detail',
        placeholder: 'Laptop Detail',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'resizableTextArea',
        fieldClass: 'textBoxStyle resizableTextArea',
        rows: 6,
        variant: 'outlined',
        margin: 'dense',
        maxLength: 5000,
        formElementClass: 'form-input-box',
        errors: [{ type: 'maxLength', message: 'Comments increased than 5000 characters' }],
      },
      {
        type: 'renderer',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'UploadContract',
      },
      {
        label: 'Current Address',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'current_address',
        name: 'current_address',
        placeholder: 'Current Address',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'resizableTextArea',
        fieldClass: 'textBoxStyle resizableTextArea',
        rows: 6,
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Permanent Address',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'permanent_address',
        name: 'permanent_address',
        placeholder: 'Permanent Address',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'resizableTextArea',
        fieldClass: 'textBoxStyle resizableTextArea',
        rows: 6,
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        maxLength: 2000,
        errors: [{ type: 'maxLength', message: 'Address increased than 2000 characters' }],
      },
      {
        label: 'General Note',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'general_note',
        name: 'general_note',
        placeholder: 'General Note',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'resizableTextArea',
        fieldClass: 'textBoxStyle resizableTextArea',
        rows: 6,
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        maxLength: 5000,
        errors: [{ type: 'maxLength', message: 'Note increased than 5000 characters' }],
      },
    ],
  },
  {
    groupLabel: ' HR Detail',
    groupLabelClass: 'fieldset-label',
    key: 'company_hr_detail',
    hiddenKey: 'company_hr_detail',
    groupClass: 'form-fieldset',
    inputs: [
      {
        label: 'CTC',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'company_hr_ctc',
        name: 'company_hr_ctc',
        placeholder: 'CTC',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Laptop Detail',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'company_hr_laptop_detail',
        name: 'company_hr_laptop_detail',
        placeholder: 'Laptop Detail',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'resizableTextArea',
        fieldClass: 'textBoxStyle resizableTextArea',
        rows: 6,
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        type: 'renderer',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'UploadCompanyHrContract',
      },
    ],
  },
  {
    groupLabel: 'Account Detail',
    groupLabelClass: 'fieldset-label',
    key: 'account_detail',
    hiddenKey: 'account_detail',
    groupClass: 'form-fieldset',
    inputs: [
      {
        label: 'Name As Per Bank Records',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'name_as_per_bank',
        name: 'name_as_per_bank',
        placeholder: 'Name As Per Bank Records',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Bank Name',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'bank_name',
        name: 'bank_name',
        placeholder: 'Bank Name',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Bank Address',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'bank_address',
        name: 'bank_address',
        placeholder: 'Bank Address',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'resizableTextArea',
        fieldClass: 'textBoxStyle resizableTextArea',
        rows: 3,
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Account Number',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'account_number',
        name: 'account_number',
        placeholder: 'Account Number',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        pattern: Validation.numberOnly,
        maxLength: 30,
        minLength: 8,
        errors: [
          { type: 'pattern', message: 'Please enter a valid account number' },
          { type: 'minLength', message: 'Account number must be greater than 8 digits' },
        ],
        formElementClass: 'form-input-box',
      },
      {
        label: 'IFSC Code',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'ifsc_code',
        name: 'ifsc_code',
        placeholder: 'IFSC Code',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Swift Code',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'swift_code',
        name: 'swift_code',
        placeholder: 'Swift Code',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'PAN Number',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'pan_number',
        name: 'pan_number',
        placeholder: 'PAN Number',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Aadhar Number',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'aadhar_number',
        name: 'aadhar_number',
        placeholder: 'Aadhar Number',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        type: 'renderer',
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
        key: 'UploadDocuments',
      },
    ],
  },
  // {
  //   groupLabel: 'Leaves',
  //   groupLabelClass: 'fieldset-label',
  //   key: 'leaves',
  //   groupClass: 'form-fieldset',
  // },
];

// upload resume
export const FileUpload = {
  label: 'Resume',
  xs: 12,
  sm: 12,
  md: 6,
  lg: 6,
  xl: 6,
  uploadType: 'doc',
  key: 'resume_name',
  placeholder: 'Upload Resume',
  formControlClass: 'input-form-control file-upload-control',
  type: 'file',
  imageClass: 'form-control-image',
  displayImageClass: 'display-avatar-logo',
  name: 'resume',
  fieldClass: 'input-file-button',
  id: 'resume-upload',
  variant: 'outlined',
  margin: 'dense',
  formElementClass: 'form-input-box file-upload-element jd-upload',
  uploadTextClass: 'file-label',
};

// contract upload
export const ContractUpload = {
  label: 'Contract',
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
  uploadType: 'doc',
  key: 'contract_name',
  placeholder: 'Upload Contract',
  formControlClass: 'input-form-control file-upload-control',
  type: 'file',
  imageClass: 'form-control-image',
  displayImageClass: 'display-avatar-logo',
  name: 'contract',
  fieldClass: 'input-file-button',
  id: 'contract-upload',
  variant: 'outlined',
  margin: 'dense',
  formElementClass: 'form-input-box file-upload-element jd-upload',
  uploadTextClass: 'file-label',
};

// company hr contract upload
export const CompanyHrContractUpload = {
  label: 'Contract',
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
  uploadType: 'doc',
  key: 'company_hr_contract_name',
  placeholder: 'Upload Contract',
  formControlClass: 'input-form-control file-upload-control',
  type: 'file',
  imageClass: 'form-control-image',
  displayImageClass: 'display-avatar-logo',
  name: 'company_hr_contract',
  fieldClass: 'input-file-button',
  id: 'company-hr-contract-upload',
  variant: 'outlined',
  margin: 'dense',
  formElementClass: 'form-input-box file-upload-element jd-upload',
  uploadTextClass: 'file-label',
};

// employee leaves head
export const EmployeeLeavesHead = [
  { label: 'id', key: 'id', hide: true, type: 'text', headClass: '', bodyClass: '' },
  {
    label: 'Leave Type',
    key: 'leave_type',
    hide: false,
    type: 'text',
    headClass: 'w-200',
    bodyClass: '',
  },
  {
    label: 'From',
    key: 'from',
    hide: false,
    type: 'text',
    headClass: 'w-200',
    bodyClass: '',
  },
  {
    label: 'To',
    key: 'to',
    hide: false,
    type: 'text',
    headClass: 'w-200',
    bodyClass: '',
  },
  {
    label: 'No. of Days',
    key: 'total_days',
    hide: false,
    type: 'text',
    headClass: 'w-200',
    bodyClass: '',
  },
  {
    label: 'Status',
    key: 'status',
    hide: false,
    type: 'text',
    headClass: 'w-200',
    bodyClass: '',
  },
  {
    label: 'Approver Name',
    key: 'status',
    hide: false,
    type: 'text',
    headClass: 'w-200',
    bodyClass: '',
  },
  {
    label: 'Action',
    key: 'actions',
    hide: false,
    type: 'actions',
    headClass: 'w-150 action-head',
    actions: [
      {
        bodyClass: 'icon-success',
        type: 'edit',
        icon: Edit,
        text: 'Edit',
        click: '',
        url: '',
        actionClass: 'icon-wrapper',
      },
    ],
  },
];

// document upload
export const DocumentUploadInputs = {
  label: 'Upload Document(s)',
  displayLabel: 'Upload Documents',
  containerClass: 'interview_status_row',
  xs: 12,
  sm: 12,
  md: 12,
  lg: 3,
  xl: 3,
  documentTypeName: 'documentList.0.document_type',
  documentName: 'documentList.0.document',
  documentUploadType: 'file',
  documentUploadId: 'document-0-value',
  uploadTextClass: 'file-label',
  formControlClass: 'input-form-control',
  fieldClass: 'text-box-style',
  documentTypePlaceholder: 'Select or Create Doc Type',
  documentPlaceholder: 'Upload Document',
  variant: 'outlined',
  margin: 'dense',
  inputClass: 'input-file-button',
  keyValue: 1,
  formElementClass: 'form-input-box',
};
