import React from 'react';
import { Tabs, LeavesHead, ResponseData, HrmsLeavesTabs } from '../../data/LeavesData';
import { TabCustom, TableCustom } from '.';
import { commonFn } from '../../util/commonFn';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export const Leaves = ({
  match,
  data = [],
  head = [],
  tabs = [],
  module,
  count = 0,
  pagination = [],
  skip = 0,
  errorMessage,
  hiddenKeys = [],
  inProgress,
}) => {
  const history = useHistory();

  // Actions click function
  const clickOnCell = (data, event) => {
    event.stopPropagation();
    const leaveId = data.row[0].value;
    if (module === 'hrms') {
      if ((leaveId && data.action?.key) || (leaveId && data.action?.type === 'view')) {
        history.push(`/hrms/leaves/view/${leaveId}`);
      }
      if (leaveId && data?.action?.type === 'edit') {
        history.push(`/hrms/leaves/edit/${leaveId}`);
      }
    } else if (module === 'company') {
      if ((leaveId && data.action?.key) || (leaveId && data.action?.type === 'view')) {
        history.push(`/company/leaves/view/${leaveId}`);
      }
      if (leaveId && data?.action?.type === 'edit') {
        history.push(`/company/leaves/edit/${leaveId}`);
      }
    }
  };

  // Table data
  const tableData = {
    head: head || [],
    count: count || 0,
    data: data || [],
    skip: skip,
    clickOnCell: clickOnCell,
    pagination: pagination || [],
    errorMessage: errorMessage || 'No data found',
    hiddenKeys: hiddenKeys || [],
  };

  return (
    <>
      <TabCustom {...tabs} />

      <Box mb={4}>
        <TableCustom {...tableData} />
      </Box>
    </>
  );
};
