import {
  Add,
  Remove,
  VerifiedUser,
  Contacts,
  SupervisedUserCircle,
  PostAdd,
  AllInbox,
  Work,
  Business,
  PermContactCalendar,
  AccountBalance,
} from '@material-ui/icons';
import { HolidayVillage } from '@mui/icons-material';

export const Data = [
  {
    menu: 'Job Portal',
    icon: Work,
    url: '/positions',
    key: 'jobPortal',
    hiddenKeys: ['positions', 'applicants'],
    submenu: [
      {
        title: 'Positions',
        icon: PostAdd,
        url: '/positions',
        group: 'positions',
        key: 'positions',
        hide: 'true',
      },
      {
        title: 'Applicants',
        icon: AllInbox,
        url: '/applicants',
        group: 'applicants',
        key: 'applicants',
        hide: 'true',
      },
    ],
  },
  {
    menu: 'Admin',
    icon: VerifiedUser,
    key: 'admin',
    hiddenKeys: ['clients', 'users'],
    submenu: [
      {
        title: 'User Management',
        icon: SupervisedUserCircle,
        url: '/users',
        group: 'users',
        key: 'users',
        hide: 'true',
      },
    ],
  },
  {
    menu: 'Company',
    icon: Business,
    key: 'company',
    hiddenKeys: ['profile', 'employees', 'accounts'],
    submenu: [
      {
        title: 'Profile',
        icon: PermContactCalendar,
        url: '/company/profile',
        group: 'profile',
        key: 'profile',
        hide: 'true',
      },
      {
        title: 'Employees',
        icon: SupervisedUserCircle,
        url: '/company/employees',
        group: 'employees',
        key: 'employees',
        hide: 'true',
      },
      {
        title: 'Accounts',
        icon: AccountBalance,
        url: '/company/invoices',
        group: 'invoices',
        key: 'invoices',
        hide: 'true',
      },
      {
        title: 'Holidays',
        icon: HolidayVillage,
        url: '/company/holidays',
        group: 'holidays',
        key: 'holidays',
        hide: 'true',
      },
      // {
      //   title: 'Leaves',
      //   icon: HolidayVillage,
      //   url: '/company/leaves',
      //   group: 'leaves',
      //   key: 'leaves',
      //   hide: 'true',
      // },
    ],
  },
  {
    menu: 'HRMS',
    icon: PermContactCalendar,
    key: 'hrms',
    hiddenKeys: ['clients', 'employees'],
    submenu: [
      {
        title: 'Clients',
        icon: Contacts,
        url: '/hrms/clients',
        group: 'clients',
        key: 'clients',
        hide: 'true',
      },
      {
        title: 'Employees',
        icon: SupervisedUserCircle,
        url: '/hrms/employees',
        group: 'employee',
        key: 'employees',
        hide: 'true',
      },
      {
        title: 'Holidays',
        icon: HolidayVillage,
        url: '/hrms/holidays',
        group: 'holidays',
        key: 'holidays',
        hide: 'true',
      },
      // {
      //   title: 'Leaves',
      //   icon: HolidayVillage,
      //   url: '/hrms/leaves',
      //   group: 'leaves',
      //   key: 'leaves',
      //   hide: 'true',
      // },
      // {
      //   title: 'Payroll',
      //   icon: AccountBalance,
      //   url: '/hrms/payroll',
      //   group: 'payroll',
      //   key: 'payroll',
      //   hide: 'true',
      // },
    ],
  },
  {
    menu: 'Accounts',
    icon: PermContactCalendar,
    key: 'accounts',
    hiddenKeys: ['clients', 'employees'],
    submenu: [
      {
        title: 'Invoices',
        icon: Contacts,
        url: '/accounts/invoices',
        group: 'invoices',
        key: 'invoices',
        hide: 'true',
      },
      // {
      //   title: 'Expenses',
      //   icon: SupervisedUserCircle,
      //   url: '/accounts/expenses',
      //   group: 'expenses',
      //   key: 'expenses',
      //   hide: 'true',
      // },
      // {
      //   title: 'Salary',
      //   icon: AccountBalance,
      //   url: '/accounts/salary',
      //   group: 'salary',
      //   key: 'salary',
      //   hide: 'true',
      // },
    ],
  },
  // {
  //   menu: 'Leaves',
  //   icon: HolidayVillage,
  //   url: '/employee/leaves',
  //   group: 'leaves',
  //   key: 'leaves',
  //   hide: 'true',
  // },
  // {
  //   menu: 'Expenses',
  //   icon: SupervisedUserCircle,
  //   url: '/employee/expenses',
  //   group: 'expenses',
  //   key: 'expenses',
  //   hide: 'true',
  // },
  // {
  //   menu: 'Salary',
  //   icon: AccountBalance,
  //   url: '/employee/salary',
  //   group: 'salary',
  //   key: 'salary',
  //   hide: 'true',
  // },
];

export const AccordionIcon = {
  plusIcon: Add,
  minusIcon: Remove,
};
