import React from 'react';
import 'date-fns';
import {
  Card,
  CardContent,
  Grid,
  Box,
  FormGroup,
  Avatar,
  CircularProgress,
} from '@material-ui/core';

import { PrimaryButton } from './PrimaryButton';

export const ViewFormCustom = ({
  list = [],
  formClass = '',
  formVariant = 'outlined',
  isView = false,
  isEdit = false,
  value = {},
  renderer = {},
  error = null,
  inProgress = true,
  hiddenKeys = [],
  aliasObject = {},
  clickOnBtn = () => {},
  buttonSection = {
    isRequired: true,
    list: [
      { viewText: 'Edit', key: 'edit', class: 'view-edit' },
      { viewText: 'Close', key: 'close', class: 'view-close' },
    ],
  },
  groupLabelText = '',
  module = '',
  hiddenButton = [],
} = {}) => {
  return (
    <Card className={`${formClass}`} variant={formVariant}>
      <CardContent>
        {inProgress && (
          <div className="align-center mt-36">
            <CircularProgress />
          </div>
        )}
        {!inProgress && error && <div className="align-center mt-36">{error}</div>}
        {/* Form elements grouped based on the data */}
        {!inProgress &&
          !error &&
          list?.map((group, index) => (
            <div key={index}>
              {group?.key && hiddenKeys.indexOf(group.hiddenKey) == -1 && (
                <FormGroup className={group?.groupClass}>
                  {/*  Company avatar */}
                  <div className={group?.avatar?.formControlClass}>
                    {group?.avatar && (
                      <>
                        {value?.company_logo?.length <= 2 && (
                          <Avatar
                            variant={group?.avatar?.variant}
                            className={group.avatar.className}
                          >
                            {value?.company_logo}
                          </Avatar>
                        )}
                        {value?.company_logo?.length > 2 && (
                          <img src={value?.company_logo} className={'company-avatar-logo1'} />
                        )}
                      </>
                    )}

                    <Box pb={2}>
                      <h5 className={group?.groupLabelClass}>
                        {module === 'hrms' &&
                          group?.key.indexOf(groupLabelText) !== -1 &&
                          value?.client_name}
                        {group?.groupLabel}
                      </h5>
                    </Box>
                  </div>

                  <Grid container spacing={1}>
                    {group?.inputs?.map((itemVal, index) => (
                      <>
                        {hiddenKeys.indexOf(itemVal.hiddenKey) == -1 && (
                          <>
                            {itemVal?.type === 'link' && (
                              <Grid
                                item
                                xs={itemVal?.xs || 12}
                                sm={itemVal?.sm || 12}
                                md={itemVal?.md || 6}
                                lg={itemVal?.lg || 4}
                                xl={itemVal?.xl || 4}
                              >
                                <Box className={itemVal?.formElementClass}>
                                  <label> {itemVal?.label} </label>
                                  <Box pt={1}>
                                    <a href={value?.[itemVal?.name]} target={itemVal?.target}>
                                      {value?.[itemVal?.displayKey]}
                                    </a>
                                  </Box>
                                </Box>
                              </Grid>
                            )}

                            {/* Type: Image For Logo */}
                            {itemVal?.type === 'file' && value?.[itemVal?.name]?.length > 2 && (
                              <Grid
                                item
                                xs={itemVal?.xs || 12}
                                sm={itemVal?.sm || 12}
                                md={itemVal?.md || 6}
                                lg={itemVal?.lg || 4}
                                xl={itemVal?.xl || 4}
                              >
                                <Box className={itemVal?.formElementClass}>
                                  {itemVal?.uploadType !== 'doc' && (
                                    <img
                                      src={value?.[itemVal?.name]}
                                      className={itemVal?.displayImageClass}
                                    />
                                  )}
                                </Box>
                              </Grid>
                            )}

                            {/* Type: Text */}
                            {itemVal?.type !== 'linkButton' &&
                              itemVal?.action !== 'create' &&
                              itemVal?.type !== 'toggle' &&
                              itemVal?.type !== 'file' &&
                              itemVal?.type != 'link' &&
                              itemVal?.type != 'dropzone' &&
                              itemVal?.type !== 'renderer' && (
                                <Grid
                                  item
                                  xs={itemVal?.xs || 12}
                                  sm={itemVal?.sm || 12}
                                  md={itemVal?.md || 6}
                                  lg={itemVal?.lg || 4}
                                  xl={itemVal?.xl || 4}
                                >
                                  <Box className={itemVal?.formElementClass} key={index}>
                                    <label className={itemVal?.labelClass}>
                                      {aliasObject?.[itemVal?.aliasLabel] || itemVal?.label}
                                    </label>
                                    <Box pt={1}>
                                      <p className="text-muted">
                                        {(itemVal?.displayKey && value?.[itemVal?.displayKey]) ||
                                          value?.[itemVal?.name] ||
                                          (itemVal?.label && '-')}
                                      </p>
                                    </Box>
                                  </Box>
                                </Grid>
                              )}
                            {itemVal?.type === 'renderer' && <> {renderer?.[itemVal?.key]?.()} </>}
                          </>
                        )}
                      </>
                    ))}
                  </Grid>
                </FormGroup>
              )}
            </div>
          ))}

        {!inProgress && !error && buttonSection.isRequired && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box mt={2}>
                {buttonSection?.list?.map((button, buttonIndex) => (
                  <>
                    {button?.key && hiddenButton.indexOf(button?.key) == -1 && (
                      <span
                        className="view-button"
                        onClick={(e) => clickOnBtn({ e, list, isView, button })}
                        key={buttonIndex}
                      >
                        <PrimaryButton text={button.viewText} class={button.class} />
                      </span>
                    )}
                  </>
                ))}
              </Box>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};
