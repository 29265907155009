import React, { useEffect } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { HomeRouter } from './home/HomeRouter';
import { PositionsMain } from './positions';
import { ApplicantsMain } from './applicants';
import { UsersMain } from './users';
import { ProfileMain } from './profile';
import { Header, Sidebar } from './common';
import { useDispatch, useSelector } from 'react-redux';
import { isAuth } from '../redux/sign/action';
import { commonFn } from '../util/commonFn';
import { NotFound } from './common';
import { HrmsLanding } from './hrms/HrmsLanding';
import { CompanyLanding } from './company/CompanyLanding';
import { AccountsLanding } from './accounts/AccountsLanding';
import { EmployeeLanding } from './employee/EmployeeLanding';

export const Landing = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { userInfo, isAuthicatedProcess, isAuthicatedError, logoutSuccess, userLogoutPorcess } =
    useSelector(({ sign }) => ({
      ...sign,
    }));

  useEffect(() => {
    if (!userInfo) {
      dispatch(isAuth());
    }
  }, []);

  useEffect(() => {
    if (!isAuthicatedProcess && userInfo && !isAuthicatedError && '/' === location.pathname) {
      history.push('/positions');
    }

    if (
      (!isAuthicatedProcess &&
        !userInfo &&
        isAuthicatedError &&
        '/reset-password' !== location.pathname) ||
      (logoutSuccess?.data && '/' === location.pathname)
    ) {
      history && history.push('/login');
    }
  }, [userInfo, isAuthicatedProcess, history, isAuthicatedError, logoutSuccess]);

  return (
    <div>
      <Header />
      <Sidebar />
      {userInfo && !userLogoutPorcess && (
        <>
          <Header />
          <Sidebar />
          <div className="main-content">
            <Switch>
              {/* <Route path={`/home`} component={PositionsMain} /> */}
              {commonFn.menuHiddenKeys.indexOf('positions') === -1 && (
                <Route path={`/positions`} component={PositionsMain} />
              )}
              {commonFn.menuHiddenKeys.indexOf('applicants') === -1 && (
                <Route path={`/applicants`} component={ApplicantsMain} />
              )}
              {commonFn.menuHiddenKeys.indexOf('users') === -1 && (
                <Route path={`/users`} component={UsersMain} />
              )}
              <Route path={`/profile`} component={ProfileMain} />

              {commonFn.menuHiddenKeys.indexOf('company') === -1 && (
                <Route path={`/company`} component={CompanyLanding} />
              )}

              {commonFn.menuHiddenKeys.indexOf('hrms') === -1 && (
                <Route path={`/hrms`} component={HrmsLanding} />
              )}

              {commonFn.menuHiddenKeys.indexOf('accounts') === -1 && (
                <Route path={`/accounts`} component={AccountsLanding} />
              )}

              {commonFn.menuHiddenKeys.indexOf('employee') === -1 && (
                <Route path={`/employee`} component={EmployeeLanding} />
              )}

              <Route path={`**`} component={NotFound} />
              {/* <Redirect exact path={`/`} to={`/positions`} /> */}
            </Switch>
          </div>
        </>
      )}

      {(!userInfo || userLogoutPorcess) && <div className="landing-quark">Loading...</div>}
    </div>
  );
};
