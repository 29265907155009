import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { CompanyLeaves } from '.';
import { EditLeave } from '.';
import { AttendanceRegister } from '.';

export const LeavesRouter = () => {
  return (
    <>
      <Switch>
        <Route exact path={'/company/leaves'} component={CompanyLeaves} />
        <Route
          path={'/company/leaves/edit/:id'}
          render={(props) => <EditLeave isEdit={true} {...props} />}
        />
        <Route exact path={'/company/attendance'} component={AttendanceRegister} />
      </Switch>
    </>
  );
};
