import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { EmployeeLeavesMain } from './leaves';
import { NotFound } from '../common';

export const EmployeeLanding = () => {
  return (
    <>
      <Switch>
        <Route path={`/employee/profile`} component={NotFound} />
        <Route path={`/employee/leaves`} component={EmployeeLeavesMain} />
        <Route path={`**`} component={NotFound} />
      </Switch>
    </>
  );
};
