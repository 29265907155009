import React, { useState, useEffect } from 'react';
import { Tabs, HrmsTabs } from '../../data/EmployeeData';
import {
  FormInputsData,
  FileUpload,
  ContractUpload,
  EmployeeLeavesHead,
  DocumentUploadInputs,
  CompanyHrContractUpload,
} from '../../data/EditEmployeeData';
import { TabCustom, FormCustom } from '.';
import { createEmployee, employeeFind, updateEmployee } from '../../redux/hrms/action';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackBarLayout } from '../../redux/common/action';
import axios from 'axios';
import { apiList } from '../../util/apiList';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useLocation, useHistory } from 'react-router-dom';
import { positionTypesFindAll } from '../../redux/master/action';
import { commonFn } from '../../util/commonFn';
import {
  citiesFindAll,
  statesFindAll,
  countriesFindAll,
  createCountry,
  createState,
  createCity,
} from '../../redux/cities/action';
import { clientsFindAll } from '../../redux/clients/action';
import { CircularProgress, Grid, Link, Box, FormControl, TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import CreatableSelect from 'react-select/creatable';
import { createDocumentType, documentTypesFindAll } from '../../redux/document_type/action';
import { useFieldArray } from 'react-hook-form';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { skillsFindAll, createSkill } from '../../redux/skills/action';

export const AddOrEditEmployee = ({
  match,
  isEdit,
  isView,
  hiddenKey,
  readOnly,
  url,
  module,
  options = {},
  values = {},
}) => {
  const {
    employeeCreateProcess,
    employeeCreateError,
    employeeCreateData,
    employeeFindProcess,
    employeeFindError,
    employeeFindData,
    employeeUpdateProcess,
    employeeUpdateError,
    employeeUpdateData,
    positionTypesFindAllProcess,
    positionTypesFindAllError,
    positionTypesFindAllData,
    cityFindAllProcess,
    cityFindAllError,
    cityFindAllData,
    stateFindAllProcess,
    stateFindAllError,
    stateFindAllData,
    countryFindAllProcess,
    countryFindAllError,
    countryFindAllData,
    countryCreateProcess,
    countryCreateError,
    countryCreateData,
    stateCreateProcess,
    stateCreateError,
    stateCreateData,
    cityCreateProcess,
    cityCreateError,
    cityCreateData,
    clientsFindAllProcess,
    clientsFindAllError,
    clientsFindAllData,
    documentTypesFindAllProcess,
    documentTypesFindAllError,
    documentTypesFindAllData,
    documentTypesCreateProcess,
    documentTypesCreateError,
    documentTypesCreateData,
    skillsFindAllProcess,
    skillsFindAllError,
    skillsFindAllData,
    skillCreateProcess,
    skillCreateError,
    skillCreateData,
  } = useSelector(({ employees, positionTypes, citiesNew, clients, documentTypes, skills }) => ({
    ...employees,
    ...positionTypes,
    ...citiesNew,
    ...clients,
    ...documentTypes,
    ...skills,
  }));

  const location = useLocation();
  const history = useHistory();

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isRouteChange, setIsRouteChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileUpload, setFileUpload] = useState(FileUpload);
  const [contractUpload, setContractUpload] = useState(ContractUpload);
  const [companyHrContractUpload, setCompanyHrContractUpload] = useState(CompanyHrContractUpload);
  const [isUploading, setIsUploading] = useState(false);
  const [isContractUploading, setIsContractUploading] = useState(false);
  const [isCompanyHrContractUploading, setIsCompanyHrContractUploading] = useState(false);
  const [isDocumentUploading, setIsDocumentUploading] = useState({});
  const [hiddenKeys, setHiddenKeys] = useState([]);
  const [docUpload, setDocUpload] = useState([]);
  const [readOnlyKeys, setReadOnlyKeys] = useState([]);

  const MySwal = withReactContent(Swal);
  const empDocumentGroupKey = 'employee';

  let tabsData = [];

  if (location?.pathname.indexOf('hrms') !== -1) {
    module = 'hrms';
    tabsData = HrmsTabs;
  } else if (location?.pathname.indexOf('company') !== -1) {
    tabsData = Tabs;
    module = 'company';
  }

  // set hidden key in form inputs
  useEffect(() => {
    if (FormInputsData && module && module === 'company') {
      setHiddenKeys(() => ['client_id', 'hr_detail', 'account_detail']);
      setReadOnlyKeys(() => [
        'company_hr_ctc',
        'company_hr_laptop_detail',
        'UploadCompanyHrContract',
      ]);
    }
  }, [module, FormInputsData]);

  // tabs
  const tabs = {
    ...tabsData,
    list: commonFn.updateLink(tabsData?.list, match),
  };

  // form data
  useEffect(() => {
    setFormData(() => ({
      list: FormInputsData,
      isEdit,
      isView,
      values: {},
      error: '',
      inProgress: isEdit ? true : false,
      options: {},
      groupLabelText: 'company_hr_detail',
    }));
    setIsRouteChange(true);
    match?.params?.id && dispatch(employeeFind(match?.params?.id));
    dispatch(positionTypesFindAll());
    dispatch(citiesFindAll());
    dispatch(statesFindAll());
    dispatch(countriesFindAll());
    dispatch(clientsFindAll());
    dispatch(skillsFindAll());
    setTimeout(() => setIsRouteChange(false), 50);
    setIsFormSubmitted(() => false);
    dispatch(documentTypesFindAll(empDocumentGroupKey));
  }, [match?.params?.id]);

  // Employee data
  useEffect(() => {
    if (isEdit) {
      if (!employeeFindProcess && !employeeFindError && employeeFindData?.data) {
        setFormData((data) => ({ ...data, values: employeeFindData?.data, inProgress: false }));
      } else if (!employeeFindProcess && employeeFindError && !employeeFindData?.data) {
        const error = employeeFindData?.message || employeeFindError?.message;
        setFormData((data) => ({ ...data, error, inProgress: false }));
      }
    }
  }, [employeeFindProcess, employeeFindError, employeeFindData]);

  // clients
  useEffect(() => {
    if (!clientsFindAllProcess && !clientsFindAllError && clientsFindAllData?.data) {
      const clients =
        (clientsFindAllData &&
          clientsFindAllData?.data?.list?.map((client) => {
            return { label: client?.company_name, value: client?.id };
          })) ||
        [];
      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && employeeFindData?.data
            ? {
                ...data.values,
                client_id: commonFn.getMultiValueFromId(
                  employeeFindData?.data?.client_id,
                  clients,
                )[0],
              }
            : data.values,
        options: { ...data.options, clients },
      }));
    }
  }, [clientsFindAllProcess, clientsFindAllError, clientsFindAllData, employeeFindData]);

  // Position types
  useEffect(() => {
    if (
      !positionTypesFindAllProcess &&
      !positionTypesFindAllError &&
      positionTypesFindAllData?.data
    ) {
      const contractType =
        (positionTypesFindAllData &&
          positionTypesFindAllData?.data?.list?.map((type) => {
            return { label: type?.position_type, value: type?.id };
          })) ||
        [];
      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && employeeFindData?.data
            ? {
                ...data.values,
                contract_type_id: commonFn.getMultiValueFromId(
                  employeeFindData?.data?.contract_type,
                  contractType,
                ),
              }
            : data.values,
        options: { ...data.options, contractType },
      }));
    }
  }, [
    positionTypesFindAllProcess,
    positionTypesFindAllError,
    positionTypesFindAllData,
    employeeFindData,
  ]);

  // Country
  useEffect(() => {
    if (!countryFindAllProcess && !countryFindAllError && countryFindAllData?.data) {
      const country =
        (countryFindAllData &&
          countryFindAllData?.data?.list?.map((country) => {
            return { label: country?.country, value: country?.id };
          })) ||
        [];

      const getId = formData.values?.country_id?.__isNew__
        ? commonFn.getIdfromSingleValue(formData.values.country_id?.value, country).value
        : employeeFindData?.data?.country_id;

      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && employeeFindData?.data
            ? {
                ...data.values,
                country_id: commonFn.getMultiValueFromId(getId, country)[0],
              }
            : data.values,
        options: { ...data.options, country },
      }));
    }
  }, [countryFindAllProcess, countryFindAllError, countryFindAllData, employeeFindData]);

  // City
  useEffect(() => {
    if (!cityFindAllProcess && !cityFindAllError && cityFindAllData?.data) {
      const city =
        (cityFindAllData &&
          cityFindAllData?.data?.list?.map((city) => {
            return { label: city?.city, value: city?.id };
          })) ||
        [];

      const getId = formData.values?.city_id?.__isNew__
        ? commonFn.getIdfromSingleValue(formData.values.city_id?.value, city).value
        : employeeFindData?.data?.city_id;

      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && employeeFindData?.data
            ? {
                ...data.values,
                city_id: commonFn.getMultiValueFromId(getId, city)[0],
              }
            : data.values,
        options: { ...data.options, city },
      }));
    }
  }, [cityFindAllProcess, cityFindAllError, cityFindAllData, employeeFindData]);

  // State
  useEffect(() => {
    if (!stateFindAllProcess && !stateFindAllError && stateFindAllData?.data) {
      const state =
        (stateFindAllData &&
          stateFindAllData?.data?.list?.map((state) => {
            return { label: state?.state, value: state?.id };
          })) ||
        [];

      const getId = formData.values?.state_id?.__isNew__
        ? commonFn.getIdfromSingleValue(formData.values.state_id?.value, state).value
        : employeeFindData?.data?.state_id;

      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && employeeFindData?.data
            ? {
                ...data.values,
                state_id: commonFn.getMultiValueFromId(getId, state)[0],
              }
            : data.values,
        options: { ...data.options, state },
      }));
    }
  }, [stateFindAllProcess, stateFindAllError, stateFindAllData, employeeFindData]);

  // Skills
  useEffect(() => {
    if (!skillsFindAllProcess && !skillsFindAllError && skillsFindAllData?.data) {
      const skills =
        (skillsFindAllData &&
          skillsFindAllData?.data?.list?.map((skill) => {
            return { label: skill?.skill, value: skill?.id };
          })) ||
        [];

      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && employeeFindData?.data
            ? {
                ...data.values,
                skill_id: commonFn.getMultiValueFromId(employeeFindData?.data?.skill_id, skills),
              }
            : data.values,
        options: { ...data.options, skills },
      }));
    }
  }, [skillsFindAllProcess, skillsFindAllError, skillsFindAllData, employeeFindData]);

  // document types find all
  useEffect(() => {
    if (!documentTypesCreateProcess && !documentTypesCreateError && documentTypesCreateData?.data) {
      dispatch(documentTypesFindAll(empDocumentGroupKey));
    }
  }, [documentTypesCreateProcess, documentTypesCreateError, documentTypesCreateData]);

  // create document type
  const createNewDocumentType = (docType) => {
    const docTypeData = {
      document_type: docType,
      group_key: empDocumentGroupKey,
    };
    dispatch(createDocumentType(docTypeData));
  };

  // set document types in state
  useEffect(() => {
    if (!documentTypesFindAllProcess && !documentTypesFindAllError && documentTypesFindAllData) {
      const document_types =
        documentTypesFindAllData?.data?.list?.map((docType) => {
          return { label: docType?.type, value: docType?.id };
        }) || [];

      setFormData((data) => ({
        ...data,
        options: { ...data.options, document_types: document_types },
      }));
    }
  }, [documentTypesFindAllProcess, documentTypesFindAllError, documentTypesFindAllData]);

  // create country onchange event
  const createNewCountry = (country) => {
    const countryData = {
      country: country,
    };
    dispatch(createCountry(countryData));
  };

  // Refresh country after creating
  useEffect(() => {
    if (!countryCreateProcess && !countryCreateError && countryCreateData?.data) {
      const countryValue = formData.values?.country_id;
      const country = countryValue &&
        countryValue?.__isNew__ && {
          label: countryCreateData?.data?.country,
          value: countryCreateData?.data?.id,
        };

      if (countryValue?.__isNew__) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            country_id: country,
          },
        }));
      }
    }
  }, [countryCreateProcess, countryCreateError, countryCreateData]);

  // create state onchange event
  const createNewState = (state) => {
    const stateData = {
      state: state,
    };
    dispatch(createState(stateData));
  };

  // Refresh state after creating new
  useEffect(() => {
    if (!stateCreateProcess && !stateCreateError && stateCreateData?.data) {
      const stateValue = formData.values?.state_id;
      const state = stateValue &&
        stateValue?.__isNew__ && {
          label: stateCreateData?.data?.state,
          value: stateCreateData?.data?.id,
        };

      if (stateValue?.__isNew__) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            state_id: state,
          },
        }));
      }
    }
  }, [stateCreateProcess, stateCreateError, stateCreateData]);

  // create city onchange event
  const createNewCity = (city) => {
    const cityData = {
      city: city,
    };
    dispatch(createCity(cityData));
  };

  // Refresh city after creating new
  useEffect(() => {
    if (!cityCreateProcess && !cityCreateError && cityCreateData?.data) {
      const cityValue = formData.values?.city_id;
      const city = cityValue &&
        cityValue?.__isNew__ && {
          label: cityCreateData?.data?.city,
          value: cityCreateData?.data?.id,
        };

      if (cityValue?.__isNew__) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            city_id: city,
          },
        }));
      }
    }
  }, [cityCreateProcess, cityCreateError, cityCreateData]);

  // change event
  const changeEvent = async (data) => {
    if (data?.value && data?.value?.__isNew__) {
      // if country id
      if (data?.field?.name === 'country_id') {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, country_id: data?.value },
        }));
        createNewCountry(data?.value?.value);
      }

      // if state id
      if (data?.field?.name === 'state_id') {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, state_id: data?.value },
        }));
        createNewState(data?.value?.value);
      }

      // if city id
      if (data?.field?.name === 'city_id') {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, city_id: data?.value },
        }));
        createNewCity(data?.value?.value);
      }
    }

    // skill
    if (data?.field?.name === 'skill_id') {
      const newValue = getNewValue(data?.value);
      if (newValue?.__isNew__ && newValue?.value) {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, skill_id: data?.value },
        }));
        createNewSkill(newValue?.value);
      }
    }
  };

  // create new skill
  const createNewSkill = (skill) => {
    const skillData = {
      skill: skill,
    };
    dispatch(createSkill(skillData));
  };

  const getNewValue = (value) => {
    let newValue = '';
    value &&
      value?.map((value) => {
        if (value && value?.__isNew__) {
          newValue = value;
        }
      });
    return newValue;
  };

  // Refresh skill after creating new
  useEffect(() => {
    if (!skillCreateProcess && !skillCreateError && skillCreateData?.data) {
      const skillValue = formData.values?.skill_id;

      let isNewValue = false;
      const skills =
        Array.isArray(skillValue) &&
        skillValue.map((skill) => {
          if (skill && skill?.__isNew__) {
            isNewValue = true;
            return { label: skillCreateData?.data?.skill, value: skillCreateData?.data?.id };
          }
          return skill;
        });

      if (isNewValue) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            skill_id: skills,
          },
        }));
      }
    }
  }, [skillCreateProcess, skillCreateError, skillCreateData]);

  // form submit
  const formSubmit = (data) => {
    const request = data;
    request.client_id = data?.client_id?.value || data?.client_id || '';
    request.contract_type_id = data?.contract_type_id?.value || data?.contract_type || '';
    request.country_id = commonFn.getIdFromMultiValue(data?.country_id).value;
    request.city_id = commonFn.getIdFromMultiValue(data?.city_id).value;
    request.state_id = commonFn.getIdFromMultiValue(data?.state_id).value;
    request.skill_id = data?.skill_id.length ? commonFn.getIdFromMultiValue(data?.skill_id) : '';

    const documents = [];
    data.documentList?.forEach((obj) => {
      if (obj.document_type?.value && obj.document) {
        let typeId = obj.document_type?.value;

        if (obj.document_type.__isNew__ === true) {
          typeId = commonFn.getIdfromSingleValue(
            obj.document_type.value,
            formData.options.document_types,
          )?.value;
        }
        documents.push({
          id: obj.id || '',
          document_type_id: typeId,
          document_type: obj.document_type?.label || '',
          document: obj.document || '',
          document_name: obj.document_name || '',
        });
      }
    });
    request.documents = documents;

    if (isEdit) {
      setIsFormSubmitted(() => true);
      setIsLoading(() => true);
      dispatch(updateEmployee(match?.params?.id, request));
    } else {
      MySwal.fire({
        title: 'Are you sure?',
        html: `You are about to create a new User with email id: <span class="text-yellow"><b> ${data?.flipkoins_email} </b></span>.`,
        icon: 'warning',
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Create',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          setIsFormSubmitted(() => true);
          setIsLoading(() => true);
          dispatch(createEmployee(request));
        }
      });
    }
  };

  // create employee success response
  useEffect(() => {
    if (isFormSubmitted) {
      if (!employeeCreateProcess && !employeeCreateError && employeeCreateData) {
        dispatch(openSnackBarLayout(employeeCreateData.message, 'success', 1000));
        setIsLoading(() => false);
        history.push(`/hrms/employees/view/${employeeCreateData?.data?.id}`);
      } else if (employeeCreateError) {
        setIsLoading(() => false);
        dispatch(openSnackBarLayout(employeeCreateError.message, 'error', 1000));
      }
    }
  }, [employeeCreateProcess, employeeCreateError, employeeCreateData]);

  // update employee success response
  useEffect(() => {
    if (isFormSubmitted) {
      if (!employeeUpdateProcess && !employeeUpdateError && employeeUpdateData) {
        dispatch(openSnackBarLayout(employeeUpdateData.message, 'success', 1000));
        setIsLoading(() => false);
        if (module === 'hrms') {
          history.push(`/hrms/employees/view/${employeeUpdateData?.data?.id}`);
        } else if (module === 'company') {
          history.push(`/company/employees/view/${employeeUpdateData?.data?.id}`);
        }
      } else if (employeeUpdateError) {
        setIsLoading(() => false);
        dispatch(openSnackBarLayout(employeeUpdateError?.message, 'error', 1000));
      }
    }
  }, [employeeUpdateProcess, employeeUpdateError, employeeUpdateData]);

  // upload resume
  const fileChange = async (e, watch) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target?.files[0];
      var formData = new FormData();
      formData.append('folderName', 'cv');
      formData.append('file', file);
      setIsUploading(() => true);

      const response = await axios.post(apiList.fileUpload, formData, {
        headers: {},
        withCredentials: true,
      });
      const result = response?.data || {};

      setIsUploading(() => false);
      if (result?.data) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            ...watch,
            resume: result?.data?.uploaded_url,
            resume_name: result?.data?.original_name,
          },
        }));
      } else {
        dispatch(openSnackBarLayout('Resume is not uploaded', 'error', 1000));
      }
    }
  };

  // remove uploaded resume
  const removeResume = (watch) => {
    setFormData((data) => ({
      ...data,
      values: {
        ...data.values,
        ...watch,
        resume: '',
        resume_name: '',
      },
    }));
  };

  // Upload Resume component
  const UploadResume = (form, watch) => {
    return (
      <>
        <Grid container spacing={1}>
          <Grid
            item
            xs={fileUpload?.xs || 12}
            sm={fileUpload?.sm || 12}
            md={fileUpload?.md || 6}
            lg={fileUpload?.lg || 4}
            xl={fileUpload?.xl || 4}
          >
            <Box className={fileUpload?.formElementClass}>
              <label className="interview-label">{fileUpload.label}</label>
              {isUploading && (
                <div className="loader">
                  <CircularProgress />
                </div>
              )}

              {!isUploading && (
                <>
                  <input
                    accept={fileUpload?.accept}
                    className={fileUpload.fieldClass}
                    id={fileUpload?.id}
                    type={fileUpload?.type}
                    onChange={(e) => fileChange(e, watch)}
                  />
                  {!formData.values?.resume && (
                    <label htmlFor={fileUpload?.id} className={fileUpload.uploadTextClass}>
                      <FileCopyIcon /> &nbsp; {fileUpload.placeholder}
                    </label>
                  )}
                  {formData.values?.resume && (
                    <div className="file-section">
                      <div
                        className={'delete-file'}
                        onClick={() => {
                          removeResume(watch);
                        }}
                      >
                        <HighlightOffIcon />
                      </div>
                      <a
                        href={formData.values?.resume}
                        target="_blank"
                        className={fileUpload.uploadTextClass}
                      >
                        <FileCopyIcon />
                        {formData.values?.resume_name}
                      </a>
                    </div>
                  )}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };
  // End upload resume component

  // upload contract file change event
  const contractFileChange = async (e, watch) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target?.files[0];
      var formData = new FormData();
      formData.append('folderName', 'contract');
      formData.append('file', file);
      setIsContractUploading(() => true);

      const response = await axios.post(apiList.fileUpload, formData, {
        headers: {},
        withCredentials: true,
      });
      const result = response?.data || {};

      setIsContractUploading(() => false);
      if (result?.data) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            ...watch,
            contract: result?.data?.uploaded_url,
            contract_name: result?.data?.original_name,
          },
        }));
      } else {
        dispatch(openSnackBarLayout('Contract is not uploaded', 'error', 1000));
      }
    }
  };

  // remove uploaded contract
  const removeContract = (watch) => {
    setFormData((data) => ({
      ...data,
      values: {
        ...data.values,
        ...watch,
        contract: '',
        contract_name: '',
      },
    }));
  };

  // Upload Contract Component
  const UploadContract = (form, watch) => {
    return (
      <>
        <Grid container spacing={1}>
          <Grid
            item
            xs={contractUpload?.xs || 12}
            sm={contractUpload?.sm || 12}
            md={contractUpload?.md || 6}
            lg={contractUpload?.lg || 4}
            xl={contractUpload?.xl || 4}
          >
            <Box className={contractUpload?.formElementClass}>
              <label className="interview-label">{contractUpload.label}</label>
              {isContractUploading && (
                <div className="loader">
                  <CircularProgress />
                </div>
              )}
              {!isContractUploading && (
                <>
                  <input
                    accept={contractUpload?.accept}
                    className={contractUpload.fieldClass}
                    id={contractUpload?.id}
                    type={contractUpload?.type}
                    prop={'contract'}
                    onChange={(e) => contractFileChange(e, watch)}
                  />
                  {!formData.values?.contract && (
                    <label htmlFor={contractUpload?.id} className={contractUpload.uploadTextClass}>
                      <FileCopyIcon /> &nbsp; {contractUpload.placeholder}
                    </label>
                  )}
                  {formData.values?.contract && (
                    <div className="file-section">
                      <div
                        className={'delete-file'}
                        onClick={() => {
                          removeContract(watch);
                        }}
                      >
                        <HighlightOffIcon />
                      </div>
                      <a
                        href={formData.values?.contract}
                        target="_blank"
                        className={contractUpload.uploadTextClass}
                      >
                        <FileCopyIcon />
                        {formData.values?.contract_name}
                      </a>
                    </div>
                  )}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };
  // End upload contract component

  // Start Company HR Contract Upload

  // upload comapny hr contract file change event
  const companyHrContractFileChange = async (e, watch) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target?.files[0];
      var formData = new FormData();
      formData.append('folderName', 'contract');
      formData.append('file', file);
      setIsCompanyHrContractUploading(() => true);

      const response = await axios.post(apiList.fileUpload, formData, {
        headers: {},
        withCredentials: true,
      });
      const result = response?.data || {};

      setIsCompanyHrContractUploading(() => false);
      if (result?.data) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            ...watch,
            company_hr_contract: result?.data?.uploaded_url,
            company_hr_contract_name: result?.data?.original_name,
          },
        }));
      } else {
        dispatch(openSnackBarLayout('Company HR Contract is not uploaded', 'error', 1000));
      }
    }
  };

  // remove uploaded hr contract
  const removeCompanyHrContract = (watch) => {
    setFormData((data) => ({
      ...data,
      values: {
        ...data.values,
        ...watch,
        company_hr_contract: '',
        company_hr_contract_name: '',
      },
    }));
  };

  const UploadCompanyHrContract = (form, watch) => {
    return (
      <>
        <Grid container spacing={1}>
          <Grid
            item
            xs={companyHrContractUpload?.xs || 12}
            sm={companyHrContractUpload?.sm || 12}
            md={companyHrContractUpload?.md || 6}
            lg={companyHrContractUpload?.lg || 4}
            xl={companyHrContractUpload?.xl || 4}
          >
            <Box className={companyHrContractUpload?.formElementClass}>
              <label className="interview-label">{companyHrContractUpload.label}</label>
              {isCompanyHrContractUploading && (
                <div className="loader">
                  <CircularProgress />
                </div>
              )}
              {!isCompanyHrContractUploading && (
                <>
                  <input
                    accept={companyHrContractUpload?.accept}
                    className={companyHrContractUpload.fieldClass}
                    id={companyHrContractUpload?.id}
                    type={companyHrContractUpload?.type}
                    prop={'companyHrContract'}
                    onChange={(e) => companyHrContractFileChange(e, watch)}
                  />
                  {!formData.values?.company_hr_contract && module === 'hrms' && (
                    <label
                      htmlFor={companyHrContractUpload?.id}
                      className={companyHrContractUpload.uploadTextClass}
                    >
                      <FileCopyIcon /> &nbsp; {companyHrContractUpload.placeholder}
                    </label>
                  )}
                  {formData.values?.company_hr_contract && (
                    <div className="file-section">
                      {readOnlyKeys.indexOf('UploadCompanyHrContract') === -1 && (
                        <>
                          <div
                            className={'delete-file'}
                            onClick={() => {
                              removeCompanyHrContract(watch);
                            }}
                          >
                            <HighlightOffIcon />
                          </div>
                        </>
                      )}
                      <a
                        href={formData.values?.company_hr_contract}
                        target="_blank"
                        className={companyHrContractUpload.uploadTextClass}
                      >
                        <FileCopyIcon />
                        {formData.values?.company_hr_contract_name}
                      </a>
                    </div>
                  )}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };
  // End upload company hr contract component

  //  upload documents
  const UploadDocuments = (form, watch) => {
    const {
      formState: { errors },
      control,
    } = form;
    const { fields, append, remove, update } = useFieldArray({
      control,
      name: 'documentList',
    });

    // onchange event
    const onChangeDocType = (data, watch, fieldIndex) => {
      if (data?.value && data?.value?.__isNew__) {
        createNewDocumentType(data?.value?.value);
      }
    };

    const handleAddClick = () => {
      append({
        document_type: '',
        document: '',
        document_name: '',
      });
    };

    useEffect(() => {
      if (!isEdit) {
        handleAddClick();
      }
    }, []);

    // Document upload
    const documentUploadChange = async (e, watch, index, list) => {
      if (e.target.files && e.target.files[0]) {
        const file = e.target?.files[0];
        var formData = new FormData();
        formData.append('folderName', 'documents');
        formData.append('file', file);
        setIsDocumentUploading((data) => ({ ...data, [list.id]: true }));

        const response = await axios.post(apiList.fileUpload, formData, {
          headers: {},
          withCredentials: true,
        });
        const result = response?.data || {};

        setIsDocumentUploading((data) => ({ ...data, [list.id]: false }));
        if (result?.data) {
          update(index, {
            ...watch?.documentList?.[index],
            document: result?.data?.uploaded_url,
            document_name: result?.data?.original_name,
          });
        } else {
          dispatch(openSnackBarLayout('Document is not uploaded', 'error', 1000));
        }
      }
    };

    // remove uploaded document
    const removeDocument = (watch, index) => {
      update(index, {
        ...watch?.documentList?.[index],
        document: '',
        document_name: '',
      });
    };

    // list document types based on the employee doc type id
    useEffect(() => {
      if (isEdit && !employeeFindProcess && !employeeFindError && employeeFindData) {
        const documents = [];
        employeeFindData?.data?.documents?.forEach((document) => {
          documents.push({
            id: document.id,
            document_type: {
              label: document.document_type,
              value: parseInt(document.document_type_id),
            },
            document: document.document,
            document_name: document.document_name,
          });
        });

        append(documents);
        if (employeeFindData?.data?.documents?.length === 0) {
          handleAddClick();
        }
      }
    }, [employeeFindProcess, employeeFindError, employeeFindData]);

    const getNonSelectedValue = (selectedValue) => {
      return formData.options?.document_types;
    };

    return (
      <>
        <h4 className="interview-label form-input-box document-view-label"> Upload Document(s)</h4>
        {fields.map((list, inputListIndex) => {
          return (
            <Grid
              container
              spacing={1}
              key={list.id}
              className={DocumentUploadInputs?.containerClass}
            >
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <Box className={DocumentUploadInputs?.formElementClass}>
                  <FormControl className={DocumentUploadInputs?.formControlClass}>
                    <Controller
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          // create select
                          <CreatableSelect
                            inputRef={ref}
                            defaultValue={commonFn.getMultiValueFromId(
                              values?.[list?.valueId],
                              options?.[list?.optionsKey],
                            )}
                            isMulti={false}
                            isClearable={true}
                            value={value}
                            className={DocumentUploadInputs?.fieldClass}
                            name={name}
                            placeholder={DocumentUploadInputs?.documentTypePlaceholder}
                            isDisabled={isView ? true : ''}
                            options={getNonSelectedValue(value) || []}
                            onChange={(e) => {
                              onChange(e);
                              onChangeDocType({ value: e, field: list }, watch, inputListIndex);
                            }}
                          />
                        );
                      }}
                      control={control}
                      key={list.keyValue}
                      name={`documentList.${inputListIndex}.document_type`}
                      rules={{
                        required: false,
                      }}
                    />
                  </FormControl>
                </Box>
              </Grid>

              {/* file upload */}
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <Box className={DocumentUploadInputs?.formElementClass}>
                  {isDocumentUploading?.[list.id] === true && (
                    <div className="loader">
                      <CircularProgress />
                    </div>
                  )}
                  {(isDocumentUploading?.[list.id] === undefined ||
                    isDocumentUploading?.[list.id] === false) && (
                    <>
                      <input
                        className={DocumentUploadInputs.inputClass}
                        id={`data${list?.id}`}
                        type={DocumentUploadInputs?.documentUploadType}
                        onChange={(e) => {
                          documentUploadChange(e, watch, inputListIndex, list);
                        }}
                      />
                      {!watch?.documentList?.[inputListIndex]?.document && (
                        <label
                          htmlFor={`data${list?.id}`}
                          className={DocumentUploadInputs?.uploadTextClass}
                        >
                          <FileCopyIcon /> &nbsp; {DocumentUploadInputs?.documentPlaceholder}
                        </label>
                      )}
                      {watch?.documentList?.[inputListIndex]?.document && (
                        <div className="file-section">
                          <div
                            className={'delete-file'}
                            onClick={() => {
                              removeDocument(watch, inputListIndex);
                            }}
                          >
                            <HighlightOffIcon />
                          </div>
                          <a
                            href={watch?.documentList?.[inputListIndex]?.document}
                            target="_blank"
                            className={DocumentUploadInputs.uploadTextClass}
                          >
                            <FileCopyIcon />
                            {watch?.documentList?.[inputListIndex]?.document_name}
                          </a>
                        </div>
                      )}
                    </>
                  )}
                </Box>
              </Grid>

              {/* action buttons (add and remove) */}
              <Grid item>
                <Box className={DocumentUploadInputs?.formElementClass}>
                  {fields.length - 1 >= 1 && (
                    <Link className={'link-button'} onClick={() => remove(inputListIndex)}>
                      <RemoveCircle className={'interview-status-icon'} />
                    </Link>
                  )}
                  {fields.length - 1 === inputListIndex && (
                    <Link className={'link-button'} onClick={handleAddClick}>
                      <AddCircle className={'interview-status-icon'} />
                    </Link>
                  )}
                </Box>
              </Grid>
              {/* button ends */}
            </Grid>
          );
        })}
      </>
    );
  };

  return (
    <>
      <TabCustom {...tabs} />
      {!isRouteChange && !formData.inProgress && (
        <FormCustom
          {...formData}
          formSubmit={formSubmit}
          changeEvent={changeEvent}
          renderer={{ UploadResume, UploadContract, UploadDocuments, UploadCompanyHrContract }}
          hiddenKeys={hiddenKeys}
          isLoading={isLoading}
          readOnlyKeys={readOnlyKeys}
        />
      )}

      {isRouteChange && (
        <div className="loader align-center">
          <CircularProgress />
        </div>
      )}
    </>
  );
};
