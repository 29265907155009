import React, { useState, useEffect } from 'react';
import { FormInputsData, ProfileTabs } from '../../../data/CompanyProfileData';
import { ViewFormCustom, TabCustom } from '../../common';
import { useHistory } from 'react-router-dom';
import { commonFn } from '../../../util/commonFn';
import { clientProfileFind } from '../../../redux/clients/action';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Grid, Box, FormControl } from '@material-ui/core';

export const ViewProfile = ({ isEdit, isView, match, docErrorMessage = 'No document found' }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});

  const { clientProfileFindProcess, clientProfileFindError, clientProfileFindData, userInfo } =
    useSelector(({ clients, sign }) => ({
      ...clients,
      ...sign,
    }));

  useEffect(() => {
    userInfo && userInfo?.data && dispatch(clientProfileFind(userInfo?.data?.company_id));
  }, [userInfo]);

  // tabs
  const tabs = {
    ...ProfileTabs,
    list: commonFn.updateLink(ProfileTabs.list, match),
  };

  useEffect(() => {
    setFormData({
      list: FormInputsData,
      tabs: tabs,
      inProgress: clientProfileFindProcess,
      error:
        (clientProfileFindData?.data === null && clientProfileFindData?.message) ||
        clientProfileFindError?.message,
      isEdit,
      isView,
      value: clientProfileFindData?.data,
      hiddenButton: ['close'],
    });
  }, [clientProfileFindData, clientProfileFindData, clientProfileFindProcess]);

  //   edit and close button function
  const clickOnBtn = (value) => {
    if (value?.button?.key === 'edit') {
      history.push(`/company/profile/edit`);
    } else if (value?.button?.key === 'close') {
      history.push(`/company/profile`);
    }
  };

  // document/file upload
  const UploadDocuments = () => {
    return (
      <Grid container>
        <h4 className="interview-label document-view-label form-input-box">
          <strong>Document(s)</strong>
        </h4>

        {(clientProfileFindData?.data?.documents.length > 0 &&
          clientProfileFindData?.data?.documents?.map((list, inputListIndex) => (
            <Grid container spacing={1} key={inputListIndex}>
              <Grid
                item
                xs={list?.xs || 12}
                sm={list?.sm || 12}
                md={list?.md || 6}
                lg={list?.lg || 4}
                xl={list?.xl || 4}
              >
                <Box className="form-input-box">
                  <label className="interview-label">{list.document_type}</label>
                </Box>
              </Grid>
              <Grid
                item
                xs={list?.xs || 12}
                sm={list?.sm || 12}
                md={list?.md || 6}
                lg={list?.lg || 4}
                xl={list?.xl || 4}
              >
                <Box className="form-input-box">
                  {list?.document && (
                    <a href={list?.document} target="_blank">
                      {list.document_name}
                    </a>
                  )}
                </Box>
              </Grid>
            </Grid>
          ))) || (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Box pl={3}>
                <p className="error">{docErrorMessage}</p>
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <>
      <TabCustom {...tabs} />
      <ViewFormCustom {...formData} clickOnBtn={clickOnBtn} renderer={{ UploadDocuments }} />
    </>
  );
};
