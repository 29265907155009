import React, { useState } from 'react';
import { ViewFormCustom, TabCustom } from '../common';
import { useHistory, useLocation } from 'react-router-dom';
import { Tabs, HrmsLeavesTabs } from '../../data/LeavesData';
import { commonFn } from '../../util/commonFn';
import { FormInputsData } from '../../data/EditLeaveData';

export const ViewLeave = (isEdit, isView, match, module) => {
  let tabsData = [];
  const { push, history } = useHistory();
  const location = useLocation();

  if (location?.pathname.indexOf('hrms') !== -1) {
    module = 'hrms';
    tabsData = HrmsLeavesTabs;
  } else if (location?.pathname.indexOf('company') !== -1) {
    tabsData = Tabs;
    module = 'company';
  }

  // tabs
  const tabs = {
    ...tabsData,
    list: commonFn.updateLink(tabsData?.list, match),
  };

  //   button click
  const clickOnBtn = (value) => {
    if (value?.button?.key === 'edit') {
      if (module === 'hrms') {
        push(`/hrms/leaves/edit/${match?.params?.id}`);
      } else if (module === 'company') {
        push(`/company/leaves/edit/${match?.params?.id}`);
      }
    } else if (value?.button?.key === 'close') {
      if (module === 'hrms') {
        push(`/hrms/leaves`);
      } else if (module === 'company') {
        push(`/company/leaves`);
      }
    }
  };

  //  form data
  const formData = {
    list: FormInputsData,
    inProgress: false, //
    // error: (!employeeFindData?.data && employeeFindData?.message) || employeeFindError?.message,
    isEdit,
    isView,
    value: {},
    tabs: tabs,
    module: module,
  };

  console.log('formData', formData);

  return (
    <>
      <TabCustom {...tabs} />
      <ViewFormCustom {...formData} clickOnBtn={clickOnBtn} />
    </>
  );
};
