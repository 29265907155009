import React, { useState } from 'react';
import { Leaves } from '../../common';
import { HrmsLeavesTabs, LeavesHead, ResponseData } from '../../../data/LeavesData';
import { useDispatch, useSelector } from 'react-redux';
import { commonFn } from '../../../util/commonFn';
import { useHistory } from 'react-router-dom';

export const HrmsLeaves = (match) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(0);
  const limit = 10;

  const tabs = {
    ...HrmsLeavesTabs,
    list: commonFn.updateLink(HrmsLeavesTabs.list, match),
  };

  const tableData = {
    tabs: tabs,
    module: 'hrms',
    count: ResponseData.length,
    skip: skip,
    data: ResponseData || [],
    head: LeavesHead,
    // inProgress: employeeFindAllProcess || false,
    // pagination: {
    //   numberOfPages: ResponseData?.data?.numberOfPages,
    //   handleChange: pageChange,
    //   currentPage: skip / limit + 1,
    // },
    // errorMessage: employeeFindAllError?.message,
    hiddenKeys: [],
  };

  return (
    <>
      <Leaves {...tableData} />
    </>
  );
};
